import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export default function Page({ children }: { children: ReactNode }) {
  const { t } = useTranslation()

  return (
    <div className="container">
      <h2 style={{ marginTop: '2rem' }}>
        <strong className="text-kicker">{t('layout.head')}</strong>
      </h2>
      {children}
    </div>
  )
}
