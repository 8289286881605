import axios from 'axios'

const api = axios.create({
  baseURL: '/api/resources',
  headers: { Authorization: `Bearer ${localStorage.token}` },
})

export async function getResource(locale, classID, registrationID) {
  const response = await api.get(`/${locale}/${classID}/${registrationID}`)
  return response.data
}
