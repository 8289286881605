import Page from '../page'

export default function Expired() {
  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">Class Completed</h1>
        <p className="text-center">This class has finished and the training materials are no longer available.</p>
      </section>
    </Page>
  )
}
