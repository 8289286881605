import { ResourceType } from '../../@types'
import { Trans, useTranslation } from 'react-i18next'

export default function SupportLearningCenter(props: ResourceType) {
  const { coursePlatform } = props
  const { t } = useTranslation()

  if (coursePlatform === 'SLC') {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md">
        <h2>Siemens EDA Training</h2>
        <p>{t('supportLearningCenter.p-1')}</p>
        <p>{t('supportLearningCenter.p-2')}</p>
        <ol>
          <li>{t('supportLearningCenter.li-1')}</li>
          <li>
            <Trans
              i18nKey="supportLearningCenter.li-2"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href="https://training.plm.automation.siemens.com/support/index.cfm?show=account_faq"
                    target="_blank"
                    rel="noreferrer"></a>
                ),
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="supportLearningCenter.li-3"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href="https://learn.sw.siemens.com/dashboard" target="_blank" rel="noreferrer"></a>,
              }}
            />
          </li>
        </ol>
        <XceleratorUrl {...props} />
      </section>
    )
  }
  return null
}

function XceleratorUrl(props: ResourceType) {
  const { xceleratorUrl } = props
  const { t } = useTranslation()

  if (xceleratorUrl) {
    return (
      <p>
        <a className="btn btn-primary" target="_blank" rel="noreferrer" href={xceleratorUrl}>
          {t('supportLearningCenter.a-1')}
        </a>
      </p>
    )
  }
  return null
}
