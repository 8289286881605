import { ResourceType } from '../../@types'

export default function MeetingInformation(props: ResourceType) {
  const { webexUrl } = props

  if (webexUrl) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>Microsoft Teams Meeting Information</h2>
        <Password {...props} />
        <AltUrl {...props} />
        <AccessCode {...props} />
        <a href={webexUrl} className="btn btn-primary" target="_blank" rel="noreferrer">
          Access the Teams Meeting
        </a>
      </section>
    )
  }
  return null
}

function Password(props: ResourceType) {
  const { webexPassword } = props

  if (webexPassword) {
    return <p>Enter the session password: {webexPassword}</p>
  }
  return null
}

function AltUrl(props: ResourceType) {
  const { webexAltUrl } = props

  if (webexAltUrl) {
    return (
      <p>
        To view in other time zones or languages, please visit{' '}
        <a href={webexAltUrl} target="_blank" rel="noreferrer">
          {webexAltUrl}
        </a>
        .
      </p>
    )
  }
  return null
}

function AccessCode(props: ResourceType) {
  const { webexAccessCode } = props

  if (webexAccessCode) {
    return (
      <p>
        <h4>To join the session by phone only:</h4>
        <ol>
          <li>Dial 1-800-637-5822.</li>
          <li>Enter the access code: {webexAccessCode}</li>
        </ol>
      </p>
    )
  }
  return null
}
