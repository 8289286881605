import Page from '../page'

export default function NotFound() {
  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">Not Found</h1>
        <p className="text-center">Sorry, the page you're looking for doesn't exist.</p>
      </section>
    </Page>
  )
}
