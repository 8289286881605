import { ResourceType, ReferenceMaterialType } from '../../@types'
import { Trans, useTranslation } from 'react-i18next'

export default function ReferenceMaterials(props: ResourceType) {
  const { referenceMaterials } = props
  const { t } = useTranslation()

  if (referenceMaterials.length) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>{t('referenceMaterials.h2-1')}</h2>
        <p>{t('referenceMaterials.p-1')}</p>
        {referenceMaterials.map(rm => (
          <ReferenceMaterial key={rm.url} {...rm} />
        ))}
        <p>{t('referenceMaterials.p-2')}</p>
        <p>
          <Trans
            i18nKey="referenceMaterials.p-3"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href="https://www.google.com/chrome" rel="noreferrer" target="_blank"></a>,
            }}
          />
        </p>
      </section>
    )
  }
  return null
}

function ReferenceMaterial(props: ReferenceMaterialType) {
  const { extension, locale, name, url } = props
  const filename = `${name}.${extension}`
  const { t } = useTranslation()

  return (
    <p>
      <a className="btn btn-primary" target="_blank" rel="noreferrer" download={filename} href={url}>
        {name} - {t(`languages.${locale}`)}
      </a>
    </p>
  )
}
