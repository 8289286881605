import Icon from '../components/icon'
import { ResourceType } from '../../@types'

export default function Skytap(props: ResourceType) {
  const { coursePlatform } = props

  if (coursePlatform === 'SkyTap') {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>Skytap</h2>
        <p>
          Your training class will be taught on our Mentor cloud-based platform. Prior to the class please check your
          connection to make sure you can successfully connect to the Skytap website.
        </p>
        <p>
          <Icon name="warning" /> Use the{' '}
          <a href="https://cloud.skytap.com/tools/connectivity" target="_blank" rel="noreferrer">
            Skytap Connectivity Checker tool
          </a>{' '}
          to check your connectivity to Skytap.
        </p>
        <p>
          Approximately two days prior to your class you will be sent a link that will take you to your cloud based
          workstation and will include your password to gain access.
        </p>
        <SkytapUrl {...props} />
      </section>
    )
  }
  return null
}

function SkytapUrl(props: ResourceType) {
  const { skytapUrl } = props

  if (skytapUrl) {
    return (
      <p>
        <a className="btn btn-primary" target="_blank" rel="noreferrer" href={skytapUrl}>
          Access Skytap
        </a>
      </p>
    )
  }
  return null
}
