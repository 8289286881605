import { ResourceType } from '../../@types'

export default function SupportLearningCenter(props: ResourceType) {
  const { coursePlatform } = props

  if (coursePlatform === 'SLC') {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md">
        <h2>Siemens EDA Training</h2>
        <p>Your session will use the Virtual Labs from our On-Demand Self Paced platform.</p>
        <p>Please follow the below steps:</p>
        <ol>
          <li>Click on the below button for access to the Self Paced platform.</li>
          <li>
            You will need to log in to your{' '}
            <a
              href="https://training.plm.automation.siemens.com/support/index.cfm?show=account_faq"
              target="_blank"
              rel="noreferrer">
              Siemens Account
            </a>{' '}
            or create an account to gain access.
          </li>
          <li>
            For future visits to the Training Library, please visit your{' '}
            <a href="https://learn.sw.siemens.com/dashboard" target="_blank" rel="noreferrer">
              Dashboard
            </a>
            .
          </li>
        </ol>
        <XceleratorUrl {...props} />
      </section>
    )
  }
  return null
}

function XceleratorUrl(props: ResourceType) {
  const { xceleratorUrl } = props

  if (xceleratorUrl) {
    return (
      <p>
        <a className="btn btn-primary" target="_blank" rel="noreferrer" href={xceleratorUrl}>
          Access Training Library
        </a>
      </p>
    )
  }
  return null
}
