import { useEffect } from 'react'
import Page from '../page'

declare global {
  interface Window {
    disw: any,
  }
}

export default function Forbidden() {
  useEffect(() => {
    const account = (window as Window).disw?.account

    if (account) {
      account.subscribeToUser((user: any, error: any) => {
        if (error) {
          console.error(error)
        } else if (user?.jwt) {
          localStorage.token = user.jwt
          window.location.reload()
        } else {
          account.login()
        }
      })
    }
  })

  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">Forbidden</h1>
        <p className="text-center">You must have a valid account on Siemens.com to visit this page.</p>
      </section>
    </Page>
  )
}
