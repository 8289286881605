import React from 'react'
import './App.css'

import { History } from 'history'
import { Router, Switch, Route } from 'react-router-dom'

import Resource from './app/resource'
import { Error, Expired, Forbidden, NotFound } from './app/errors'
import Root from './app/root'

function App({ history }: { history: History<unknown> }) {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/resources/:locale/:classID/:registrationID?">
          <Resource />
        </Route>
        <Route path="/forbidden">
          <Forbidden />
        </Route>
        <Route path="/error">
          <Error />
        </Route>
        <Route path="/expired">
          <Expired />
        </Route>
        <Route exact path="/">
          <Root />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
