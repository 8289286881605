import { ResourceType } from '../../@types'
import { dateRange } from '../utils/date'
import { Trans } from 'react-i18next'

export default function Footer(props: ResourceType) {
  const { name, startAtInLocalTime: startAt, endAtInLocalTime: endAt } = props
  const datetime = dateRange(startAt?.replace('.000Z', ''), endAt?.replace('.000Z', ''))
  const subject = `Problems with Resources for Class ${name}`
  const body = `\n\nClass:\n${name} ${datetime}`
  const mailUrl = `mailto:SysAdmin_EDA.SISW@siemens.com?subject=${subject}&body=${body}`

  return (
    <footer className="m-t-md">
      <p className="text-center text-xl">
        <strong>
          <Trans
            i18nKey={'layout.contact'}
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href={mailUrl}></a>,
            }}
          />
        </strong>
      </p>
    </footer>
  )
}
