import Page from './page'

export default function About() {
  return (
    <Page>
      <section className="flex flex-group">
        <h1 className="flex-grow">Welcome</h1>
      </section>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <p>Your training resources are provided to you when you sign up for a class through Siemens.</p>
        <p>
          Please check your email for class communications. They will include the link you need to access the training
          resources material. You will need to log in with your Siemens account to access the material.
        </p>
      </section>
    </Page>
  )
}
