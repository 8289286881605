import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Masonry from 'react-masonry-component'
import { Trans } from 'react-i18next'

import { ResourceType } from '../../@types'

import MeetingInformation from './meetingInformation'
import ReferenceMaterials from './referenceMaterials'
import Skytap from './skytap'
import SupportLearningCenter from './supportLearningCenter'
import VirtualLab from './virtualLab'
import Vlab from './vlab'
import Footer from './footer'
import CourseSchedule from './courseSchedule'
import GetCertified from './getCertified'
import Survey from './survey'

import { Error, Expired, Forbidden, NotFound } from '../errors'
import Page from '../page'
import Loading from '../loading'
import { getResource } from '../utils/api'

type Params = {
  classID: string,
  locale: string,
  registrationID: string,
}

export default function Main() {
  const { classID, locale = 'en-US', registrationID = '' }: Params = useParams()
  const [status, setStatus]: [number | undefined, (error: number) => void] = useState(0)
  const [resource, setResource]: [ResourceType | undefined, (resourceType: ResourceType) => void] = useState()

  useEffect(() => {
    if (localStorage.token) {
      getResource(locale, classID, registrationID)
        .catch(err => {
          if (err.response?.status) {
            return setStatus(err.response.status)
          }
          setStatus(500)
        })
        .then(data => {
          if (data) {
            setStatus(200)
            setResource(data)
          }
        })
    } else {
      setStatus(403)
    }
  }, [locale, classID, registrationID])

  switch (status) {
    case 200:
      return resource ? <Resource {...resource} /> : <Error />
    case 403:
      return <Forbidden />
    case 404:
      return <NotFound />
    case 410:
      return <Expired />
    case 500:
      return <Error />
    default:
      return <Loading />
  }
}

function Resource(props: ResourceType) {
  const { name } = props

  return (
    <Page>
      <section className="flex flex-group">
        <h1 className="flex-grow">{name}</h1>
      </section>

      <CourseSchedule {...props} />
      <VirtualLab {...props} />
      <SupportLearningCenter {...props} />

      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md text-center">
        <strong>
          <Trans
            i18nKey="layout.terms"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://mlc-assets.s3.us-west-2.amazonaws.com/public/eda-one-glance/LSA-V2-1-English.pdf"></a>
              ),
            }}
          />
        </strong>
      </section>

      <Masonry
        className="grid"
        options={{
          columnWidth: '.grid-sizer',
          gutter: '.gutter-sizer',
          itemSelector: '.grid-item',
          percentPosition: true,
        }}>
        <div className="gutter-sizer" />
        <div className="grid-sizer" />
        <Skytap {...props} />
        <MeetingInformation {...props} />
        <Vlab {...props} />
        <ReferenceMaterials {...props} />
        <GetCertified {...props} />
        <Survey {...props} />
      </Masonry>

      <Footer {...props} />
    </Page>
  )
}
