const dayjs = require('dayjs')

export function dateRange(start, end, year = true, _locale = 'en') {
  const date = date => dayjs(date).format('MMM D')
  start = dayjs(start)
  end = dayjs(end)

  year = year ? dayjs(start).format('YYYY') : ''

  if (start.month() !== end.month()) {
    return `${date(start)}–${date(end)}, ${year} ${start.format('h')}–${end.format('h a')}`
  } else {
    if (date(start) === date(end)) {
      return `${date(start)}, ${year} ${start.format('h')}–${end.format('h a')}`
    }
    return `${date(start)}–${end.format('D')}, ${year} ${start.format('h')}–${end.format('h a')}`
  }
}
