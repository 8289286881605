import { ResourceType } from '../../@types'
import { useTranslation } from 'react-i18next'

export default function Survey(props: ResourceType) {
  const { surveyUrl } = props
  const { t } = useTranslation()

  if (surveyUrl) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>{t('survey.h2-1')}</h2>
        <p>{t('survey.p-1')}</p>
        <p>
          <a href={surveyUrl} className="btn btn-primary" target="_blank" rel="noreferrer">
            {t('survey.a-1')}
          </a>
        </p>
      </section>
    )
  }
  return null
}
