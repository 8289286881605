import { ResourceType } from '../../@types'
import { Trans, useTranslation } from 'react-i18next'

export default function VirtualLab(props: ResourceType) {
  const { virtualLabUrl } = props
  const { t } = useTranslation()

  if (virtualLabUrl) {
    const downloadUrl = 'https://vlabs.sw.siemens.com/console/troubleshooting?'
    const subject = `My Training Resources Issue`
    const body = `\n\nDescribe the issue that occurred: `
    const mailUrl = `mailto:SysAdmin_EDA.sisw@siemens.com?subject=${subject}&body=${body}`

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      window.open(
        downloadUrl,
        'Troubleshooting',
        [
          'scrollbars=yes',
          'resizable=yes',
          'status=no',
          'location=no',
          'toolbar=no',
          'menubar=no',
          'width=600',
          'height=600',
          'left=100',
          'top=100',
        ].join(',')
      )
    }

    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md">
        <h2>{t('virtualLab.h2-1')}</h2>
        <div>
          <button className="btn btn-outline pull-right" onClick={onClick}>
            {t('virtualLab.button-1')}
          </button>
          <p>
            <Trans
              i18nKey="virtualLab.p-1"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href={mailUrl}></a>,
              }}
            />
          </p>
        </div>

        <iframe
          title="Virtual Lab"
          className="vlab-iframe"
          src={virtualLabUrl}
          width="100%"
          height="100%"
          scrolling="no"
          style={{
            height: '150px',
            width: '100%',
            border: 'none',
            overflow: 'hidden',
          }}
        />
      </section>
    )
  }
  return null
}
