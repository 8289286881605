import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de_DE from './locales/de-DE/translation.json'
import en_US from './locales/en-US/translation.json'
import es_ES from './locales/es-ES/translation.json'
import fr_FR from './locales/fr-FR/translation.json'
import it_IT from './locales/it-IT/translation.json'
import ja_JP from './locales/ja-JP/translation.json'
import ko_KR from './locales/ko-KR/translation.json'
import zh_CN from './locales/zh-CN/translation.json'
import zh_TW from './locales/zh-TW/translation.json'

const LOCALES = ['de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'zh-CN', 'zh-TW']

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: window.location.host.includes('dev'),
    detection: {
      order: ['path'],
      lookupFromPathIndex: 1,
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      'de-DE': {
        translation: de_DE,
      },
      'en-US': {
        translation: en_US,
      },
      'es-ES': {
        translation: es_ES,
      },
      'fr-FR': {
        translation: fr_FR,
      },
      'it-IT': {
        translation: it_IT,
      },
      'ja-JP': {
        translation: ja_JP,
      },
      'ko-KR': {
        translation: ko_KR,
      },
      'zh-CN': {
        translation: zh_CN,
      },
      'zh-TW': {
        translation: zh_TW,
      },
    },
    supportedLngs: LOCALES,
  })

export default i18n
