import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const LOCALES = ['de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'zh-CN', 'zh-TW']

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: window.location.host.includes('dev'),
    detection: {
      order: ['path'],
      lookupFromPathIndex: 1,
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    resources: LOCALES.reduce((object: any, locale: string) => {
      object[locale] = { translation: require(`./locales/${locale}/translation.json`) }
      return object
    }, {}),
    returnEmptyString: false,
    returnNull: false,
    supportedLngs: LOCALES,
  })
